<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />
 <div>
  <PbxHeader
   v-model:search="search"
   pbxMenuType="queueDetail"
   :periodSelector="period"
   :parseDateRange="parseDateRange"
   @select-hostname="selectHostname = $event"
   :selectedQueue="selectedQueue"
   :parseTimeRange="parseTimeRange"
   :timeSelected="selectedtime"
   :buttonSelected="buttonSelected"
   @activeBanner="(activeBanner = true), (key = !key)"
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   urlType="queue"
  />
  <div class="mx-6">
   <div class="md:pl-0 pl-10 pr- pt-5">
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="$emit('action', $event), (selectedElement = $event), separateQueueExtensionData()"
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="selectedtime = $event"
     :didNumbers="didNumbers"
    />
   </div>
  </div>
  <div class="flex ml-6 gap-2">
   <SwitchGroup as="div" class="flex items-center gap-1">
    <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
     <span class="text-sm font-medium text-gray-900">{{ $t("showQueuesOnlyStats") }}</span>
     <!-- <span class="text-sm text-gray-500">20s</span> -->
    </SwitchLabel>
    <Switch
     v-model="queuesOnlyStats"
     :class="[
      queuesOnlyStats ? 'bg-indigo-600' : 'bg-gray-200',
      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
     ]"
    >
     <span
      aria-hidden="true"
      :class="[
       queuesOnlyStats ? 'translate-x-5' : 'translate-x-0',
       'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ]"
     />
    </Switch>
    <SwitchLabel as="span" class="flex gap-1">
     <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
     <!-- <span class="text-sm text-gray-500">30s</span> -->
    </SwitchLabel>
   </SwitchGroup>
   <SwitchGroup as="div" class="flex items-center gap-1">
    <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
     <span class="text-sm font-medium text-gray-900">{{ $t("groupData") }}</span>
     <!-- <span class="text-sm text-gray-500">20s</span> -->
    </SwitchLabel>
    <Switch
     v-model="groupData"
     :class="[
      groupData ? 'bg-indigo-600' : 'bg-gray-200',
      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
     ]"
    >
     <span
      aria-hidden="true"
      :class="[
       groupData ? 'translate-x-5' : 'translate-x-0',
       'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ]"
     />
    </Switch>
    <SwitchLabel as="span" class="flex gap-1">
     <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
     <!-- <span class="text-sm text-gray-500">30s</span> -->
    </SwitchLabel>
   </SwitchGroup>
  </div>
  <div
   class="flex flex-col"
   v-if="queuesOnlyStats ? queueData.length > 0 : extensionData.length > 0"
  >
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
     <div class="overflow-hidden border-b border-gray-200 rounded-md">
      <QueueFullReportTable
       :tableData="queuesOnlyStats ? filteredQueueData : filteredExtensionData"
       :header="queuesOnlyStats ? queueHeader : extensionHeader"
       :body="queuesOnlyStats ? queueDataKey : extensionDataKey"
       :selectedtime="selectedtime"
       :queuesArray="queuesArray"
       :queuesNameArray="queuesNameArray"
       :extensionsArray="extensionsArray"
       :extensionsNameArray="extensionsNameArray"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       :queuesNameNumberArray="queuesNameNumberArray"
       v-model:searchQueue="searchQueue"
       v-model:searchQueueName="searchQueueName"
       v-model:searchExtension="searchExtension"
       v-model:searchExtensionName="searchExtensionName"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
      />
     </div>
    </div>
   </div>
  </div>
  <div
   v-else-if="
    queuesOnlyStats ? queueData.length <= 0 : extensionData.length <= 0 && selectedElement
   "
   class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0"
  >
   <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
    <div class="overflow-hidden border-b border-gray-200 rounded-md">
     <NoData />
    </div>
   </div>
  </div>
  <div v-if="didNumbers.length <= 0" class="pt-10">
   <div class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
     />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{ $t("noCallersForThisPeriod") }}
    </h3>
   </div>
  </div>
  <div v-else class="pt-10">
   <div v-if="!selectedElement" class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
     />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{ $t("pleaseSelectANumberFromTheSelectorAbove") }}
    </h3>
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import NoData from "../../components/NoData.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SuccessBanner from "../../components/SuccessBanner.vue";
import TablePagination from "../../components/TablePagination.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import QueueFullReportTable from "../../components/PBX/QueueFullReportTable.vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const keyType = {
 0: "extension",
 4: "queue",
};

const actionType = {
 1: "answered",
 "1c": "answered_calls",
 10: "transferred",
 15: "declined_solicitations",
 "15c": "declined_calls",
 13: "dissuaded",
 5: "abandoned",
};

const extensionHeader = [
 "date",
 "time",
 "queue",
 "extension",
 "calls",
 "solicited",
 "declined",
 "answered",
 "transferred",
 "answered_percentage",
 "talking_duration",
 "waiting_duration",
 "avg_talking_duration",
 "avg_waiting_duration",
];

const queueHeader = [
 "date",
 "time",
 "queue",
 "calls",
 "dissuaded",
 "abandoned",
 "transferred",
 "answered_percentage",
 "talking_duration",
 "waiting_duration",
 "avg_talking_duration",
 "avg_waiting_duration",
];

const extensionDataKey = [
 "formatedDate",
 "time",
 "queueNameNumber",
 "elementNameNumber",
 "calls_ext",
 "solicited",
 "declined_solicitations",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "avg_talking_duration",
 "avg_waiting_duration",
];

const queueDataKey = [
 "formatedDate",
 "time",
 "queueNameNumber",
 "answered",
 "dissuaded",
 "abandoned",
 "transferred",
 "answered_percentage_queue",
 "talking_duration",
 "waiting_duration",
 "avg_talking_duration",
 "avg_waiting_duration",
];

export default {
 props: ["urlType"],
 components: {
  Loading,
  PbxHeader,
  PbxDashboardSelector,
  TablePagination,
  SuccessBanner,
  QueueFullReportTable,
  Switch,
  SwitchGroup,
  SwitchLabel,
  NoData,
 },
 data() {
  return {
   groupData: true,
   searchQueue: null,
   searchQueueName: null,
   searchExtension: null,
   searchExtensionName: null,
   queuesNameArray: [],
   queuesArray: [],
   extensionsNameArray: [],
   extensionsNameNumberArray: [],
   queuesNameNumberArray: [],
   extensionsArray: [],
   queueHeader,
   queueDataKey,
   extensionHeader,
   extensionDataKey,
   queuesOnlyStats: false,
   keyType,
   actionType,
   data: {},
   selectedtime: undefined,
   isLoading: false,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   extensionData: [],
   textMonth: "",
   buttonSelected: "month",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   textMonth: "",
   buttonSelected: "month",
   settedGroupTableData: [],
   settedUngroupedTableData: [],
   didNumbers: [],
  };
 },
 methods: {
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getData(sentPeriod) {
   let period = "";
   if (sentPeriod) {
    period = sentPeriod;
   } else {
    period = this.period;
   }
   let url = `https://expert-stats-api.bluerocktel.net/api/v1/${this.hostName}/did-details?period=${period}&did=${this.selectedElement}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    console.log("file: ExpertStatisticsFullQueueReport.vue:227 ~ getData ~ res.data:", res.data);
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async setTableData(data) {
   let rawDataArray = [];
   let dataArray = [];
   let dataObj = {};
   let queueReportData = data;
   let queueReportDataUngrouped = queueReportData["ungrouped"];

   let pbxMapData =
    this.pbxMap && Object.keys(this.pbxMap).length > 0 ? this.pbxMap : this.getPbxMap();

   let queueDataKeysUngrouped = Object.keys(queueReportDataUngrouped);
   queueDataKeysUngrouped.forEach((key, index) => {
    let splitedKey = key.split(":");
    let queueNumber = splitedKey[2];
    let typeInfo = splitedKey[3];
    let elementNumber = splitedKey[4];
    let dataPeriod = splitedKey[5].length == 12 ? splitedKey[5] : splitedKey[5].slice(0, 8);
    let dataTime = splitedKey[5].length == 12 ? splitedKey[5].slice(-4) : null;
    let dataAction = splitedKey[6];
    let actionType = dataAction === "actions" ? this.actionType[splitedKey[7]] : null;
    dataObj = {};
    dataObj["queue"] = queueNumber;
    dataObj["queueName"] =
     pbxMapData && pbxMapData["call_queues"] && pbxMapData["call_queues"][queueNumber]
      ? pbxMapData["call_queues"][queueNumber]["name"]
      : null;
    dataObj["element"] = elementNumber;
    dataObj["elementName"] =
     typeInfo == 0 &&
     pbxMapData &&
     pbxMapData["extensions"] &&
     pbxMapData["extensions"][elementNumber]
      ? pbxMapData["extensions"][elementNumber]
      : typeInfo == 4 &&
        pbxMapData &&
        pbxMapData["call_queues"] &&
        pbxMapData["call_queues"][elementNumber]
      ? pbxMapData["call_queues"][elementNumber]["name"]
      : null;
    dataObj["queueNameNumber"] =
     dataObj["queue"] + "-" + (dataObj["queueName"] ? dataObj["queueName"] : "");
    dataObj["elementNameNumber"] =
     dataObj["element"] + "-" + (dataObj["elementName"] ? dataObj["elementName"] : "");
    dataObj["date"] = dataPeriod;
    dataObj["time"] = dataTime;
    dataObj["formatedDate"] = this.parseDateRange(dataPeriod.slice(0, 8));
    dataObj["keyType"] = typeInfo;
    let action = dataAction === "actions" ? actionType : dataAction;
    dataObj[action] = queueReportDataUngrouped[key];
    dataObj[`${action}`] =
     dataAction === "actions" ? queueReportDataUngrouped[key] : queueReportDataUngrouped[key];
    if (this.selectedtime && this.selectedtime.length > 1) {
     let firsQuarterHour =
      ("0" + (parseInt(this.selectedtime[0].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
      this.selectedtime[0].slice(-2);
     let lastQuarterHour =
      ("0" + (parseInt(this.selectedtime[1].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
      this.selectedtime[1].slice(-2);
     if (dataTime) {
      if (
       parseInt(firsQuarterHour) <= parseInt(dataTime) &&
       parseInt(dataTime) <= parseInt(lastQuarterHour)
      ) {
       rawDataArray.push(dataObj);
      }
     }
    } else {
     if (action && dataPeriod == splitedKey[5].slice(0, 8)) {
      rawDataArray.push(dataObj);
     }
    }
   });
   let elements = [];
   let elementsName = [];
   let elementsNameNumber = [];
   let dates = [];
   let queues = [];
   let queuesName = [];
   let queuesNameNumber = [];
   rawDataArray.forEach((el) => {
    if (el.element) {
     elements.push(el.element);
    }
    if (el.date) {
     dates.push(el.date);
    }
    if (el.queue) {
     queues.push(el.queue);
    }
    if (el.queueName) {
     queuesName.push(el.queueName);
    }
    if (el.elementName && el.keyType == 0) {
     elementsName.push(el.elementName);
    }
    if (el.element && el.elementNameNumber && el.keyType == 0) {
     elementsNameNumber.push(el.elementNameNumber);
    }
    if (el.queueNameNumber) {
     queuesNameNumber.push(el.queueNameNumber);
    }
   });
   let uniqElements = [...new Set(elements)];
   let uniqElementsName = [...new Set(elementsName)];
   let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
   let uniqDates = [...new Set(dates)];
   let uniqQueues = [...new Set(queues)];
   let uniqQueuesName = [...new Set(queuesName)];
   let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

   this.queuesArray = uniqQueues;
   this.queuesNameArray = uniqQueuesName;
   this.queuesNameNumberArray = uniqQueuesNameNumber;
   this.extensionsArray = uniqElements;
   this.extensionsNameArray = uniqElementsName;
   this.extensionsNameNumberArray = uniqElementsNameNumber;

   uniqElements.forEach((el) => {
    let filteredData = rawDataArray.filter((res) => {
     return el == res.element;
    });

    uniqDates.forEach((d) => {
     let filteredData2 = filteredData.filter((res) => {
      return d == res.date;
     });
     uniqQueues.forEach((queue) => {
      let filteredData3 = filteredData2.filter((res) => {
       return queue == res.queue;
      });
      let mergedObjs = {};
      if (filteredData3.length > 0) {
       for (let index = 0; index < filteredData3.length; index++) {
        const element = filteredData3[index];
        mergedObjs = { ...mergedObjs, ...element };
       }
       dataArray.push(mergedObjs);
      }
     });
    });
   });
   let sortedDataArray = dataArray.sort((a, b) => {
    return parseInt(a.queue) - parseInt(b.queue);
   });
   return sortedDataArray;
  },
  async setTableDataGrouped(data) {
   let rawDataArray = [];
   let dataArray = [];
   let dataObj = {};
   let queueReportData = data;
   let queueReportDataGrouped = queueReportData["grouped"];
   let queueDataKeysGrouped = Object.keys(queueReportDataGrouped);

   let pbxMapData =
    this.pbxMap && Object.keys(this.pbxMap).length > 0 ? this.pbxMap : this.getPbxMap();

   queueDataKeysGrouped.forEach((key, index) => {
    let splitedKey = key.split(":");
    let queueNumber = splitedKey[2];
    let typeInfo = splitedKey[3];
    let elementNumber = splitedKey[4];
    let dataTime = splitedKey[5].length == 12 ? splitedKey[5].slice(-4) : null;
    let dataAction = splitedKey[6];
    let actionType = dataAction === "actions" ? this.actionType[splitedKey[7]] : null;
    dataObj = {};
    dataObj["queue"] = queueNumber;
    dataObj["queueName"] =
     pbxMapData && pbxMapData["call_queues"] && pbxMapData["call_queues"][queueNumber]
      ? pbxMapData["call_queues"][queueNumber]["name"]
      : null;
    dataObj["element"] = elementNumber;
    dataObj["elementName"] =
     typeInfo == 0 &&
     pbxMapData &&
     pbxMapData["extensions"] &&
     pbxMapData["extensions"][elementNumber]
      ? pbxMapData["extensions"][elementNumber]
      : typeInfo == 4 &&
        pbxMapData &&
        pbxMapData["call_queues"] &&
        pbxMapData["call_queues"][elementNumber]
      ? pbxMapData["call_queues"][elementNumber]["name"]
      : null;
    dataObj["queueNameNumber"] =
     dataObj["queue"] + "-" + (dataObj["queueName"] ? dataObj["queueName"] : "");
    dataObj["elementNameNumber"] =
     dataObj["element"] + "-" + (dataObj["elementName"] ? dataObj["elementName"] : "");
    dataObj["time"] = dataTime;
    dataObj["keyType"] = typeInfo;
    let action = dataAction === "actions" ? actionType : dataAction;
    dataObj[action] = queueReportDataGrouped[key];
    dataObj[`${action}`] =
     dataAction === "actions" ? queueReportDataGrouped[key] : queueReportDataGrouped[key];
    if (this.selectedtime && this.selectedtime.length > 1) {
     let firsQuarterHour =
      ("0" + (parseInt(this.selectedtime[0].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
      this.selectedtime[0].slice(-2);
     let lastQuarterHour =
      ("0" + (parseInt(this.selectedtime[1].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
      this.selectedtime[1].slice(-2);
     if (dataTime) {
      if (
       parseInt(firsQuarterHour) <= parseInt(dataTime) &&
       parseInt(dataTime) <= parseInt(lastQuarterHour) &&
       typeInfo
      ) {
       rawDataArray.push(dataObj);
      }
     }
    } else {
     if (action && splitedKey[5].length == 8 && typeInfo) {
      rawDataArray.push(dataObj);
     }
    }
   });
   let elements = [];
   let elementsName = [];
   let elementsNameNumber = [];
   let dates = [];
   let queues = [];
   let queuesName = [];
   let queuesNameNumber = [];
   rawDataArray.forEach((el) => {
    if (el.element) {
     elements.push(el.element);
    }
    if (el.date) {
     dates.push(el.date);
    }
    if (el.queue) {
     queues.push(el.queue);
    }
    if (el.queueName) {
     queuesName.push(el.queueName);
    }
    if (el.elementName && el.keyType == 0) {
     elementsName.push(el.elementName);
    }
    if (el.element && el.elementNameNumber && el.keyType == 0) {
     elementsNameNumber.push(el.elementNameNumber);
    }
    if (el.queueNameNumber) {
     queuesNameNumber.push(el.queueNameNumber);
    }
   });
   let uniqElements = [...new Set(elements)];
   let uniqElementsName = [...new Set(elementsName)];
   let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
   let uniqQueues = [...new Set(queues)];
   let uniqQueuesName = [...new Set(queuesName)];
   let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

   this.queuesArray = uniqQueues;
   this.queuesNameArray = uniqQueuesName;
   this.queuesNameNumberArray = uniqQueuesNameNumber;
   this.extensionsArray = uniqElements;
   this.extensionsNameArray = uniqElementsName;
   this.extensionsNameNumberArray = uniqElementsNameNumber;

   if (this.selectedtime && this.selectedtime.length > 1) {
    let keysToSum = [
     "waiting_duration",
     "talking_duration",
     "answered",
     "dissuaded",
     "transferred",
     "abandoned",
    ];
    for (let index = 0; index < uniqElements.length; index++) {
     const uniqElement = uniqElements[index];

     for (let index = 0; index < uniqQueues.length; index++) {
      let obj = {};
      obj["waiting_duration"] = 0;
      obj["talking_duration"] = 0;
      obj["answered"] = 0;
      obj["declined_solicitations"] = 0;
      obj["transferred"] = 0;
      obj["abandoned"] = 0;
      const uniqQueue = uniqQueues[index];

      rawDataArray.forEach((el) => {
       if (el.element == uniqElement && el.queue == uniqQueue) {
        obj["element"] = el.element;
        obj["elementName"] = el.elementName;
        obj["elementNameNumber"] = el.elementNameNumber;
        obj["queue"] = el.queue;
        obj["queueName"] = el.queueName;
        obj["queueNameNumber"] = el.queueNameNumber;
        obj["keyType"] = el.keyType;
        obj["waiting_duration"] =
         obj["waiting_duration"] + (el.waiting_duration ? parseInt(el.waiting_duration) : 0);
        obj["talking_duration"] =
         obj["talking_duration"] + (el.talking_duration ? parseInt(el.talking_duration) : 0);
        obj["answered"] = obj["answered"] + (el.answered ? parseInt(el.answered) : 0);
        obj["declined_solicitations"] =
         obj["declined_solicitations"] +
         (el.declined_solicitations ? parseInt(el.declined_solicitations) : 0);
        obj["transferred"] = obj["transferred"] + (el.transferred ? parseInt(el.transferred) : 0);
        obj["abandoned"] = obj["abandoned"] + (el.abandoned ? parseInt(el.abandoned) : 0);
       }
      });

      if (obj && obj.element && obj.queue) {
       let flag = true;
       for (let index = 0; index < dataArray.length; index++) {
        const elDataArray = dataArray[index];
        if (elDataArray.element === obj.element && elDataArray.queue === obj.queue) {
         flag = false;
        }
       }
       if (flag) {
        dataArray.push(obj);
       }
      }
     }
    }
   } else {
    uniqElements.forEach((el) => {
     let filteredData = rawDataArray.filter((res) => {
      return el == res.element;
     });

     uniqQueues.forEach((queue) => {
      let filteredData2 = filteredData.filter((res) => {
       return queue == res.queue;
      });
      let mergedObjs = {};
      if (filteredData2.length > 0) {
       for (let index = 0; index < filteredData2.length; index++) {
        const element = filteredData2[index];
        mergedObjs = { ...mergedObjs, ...element };
       }
       dataArray.push(mergedObjs);
      }
     });
    });
   }

   let sortedDataArray = dataArray.sort((a, b) => {
    return parseInt(a.queue) - parseInt(b.queue);
   });
   return sortedDataArray;
  },
  async separateQueueExtensionData(groupSelected) {
   this.$emit("setLoading", true);
   this.queueData = [];
   this.extensionData = [];
   let dataArray = [];
   if (groupSelected && groupSelected === "grouped") {
    dataArray = this.groupData ? this.settedGroupTableData : this.settedUngroupTableData;
   } else {
    this.data = await this.getData();
    this.settedGroupTableData = await this.setTableDataGrouped(this.data);
    this.settedUngroupTableData = await this.setTableData(this.data);
    dataArray = this.groupData ? this.settedGroupTableData : this.settedUngroupTableData;
   }

   for (let index = 0; index < dataArray.length; index++) {
    const el = dataArray[index];
    if (el.keyType == 4) {
     if (el.queue == el.element) {
      this.queueData.push(el);
     }
    } else {
     this.extensionData.push(el);
    }
   }
   this.$emit("setLoading", false);
  },

  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
  formatTime(time) {
   let formatedTime = time.slice(0, 2) + ":" + time.slice(-2);
   return formatedTime;
  },
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  async getPbxMap() {
   if (this.hostName && this.hostName !== "null") {
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/pbxMap/${this.hostName}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );
     this.$store.dispatch("pbxMap", res.data);
     localStorage.setItem("pbxMap", JSON.stringify(res.data));

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getCallerNumbers() {
   this.queueData = [];
   this.extensionData = [];
   this.didNumbers = [];
   this.$emit("setLoading", true);
   let url = `https://expert-stats-api.bluerocktel.net/api/v1/${this.hostName}/dids?period=${this.period}`;

   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    console.log("file: ExpertStatisticsDIDReport.vue:771 ~ getCallerNumbers ~ res.data:", res.data);
    let map =
     this.pbxMap && this.pbxMap.dids && this.pbxMap.dids.length > 0
      ? this.pbxMap.dids
      : this.getPbxMap().dids;
    let data = res.data;
    for (let index = 0; index < map.length; index++) {
     const element = map[index];
     data.forEach((res) => {
      if (element.includes(res) && this.didNumbers.indexOf(element) < 0) {
       this.didNumbers.push(element);
      }
     });
    }
    console.log(
     "file: ExpertStatisticsDIDReport.vue:776 ~ data.forEach ~ didNumbers:",
     this.didNumbers
    );
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
 },
 watch: {
  selectedtime: function (val) {
   if (this.selectedElement) {
    this.separateQueueExtensionData();
   }
  },
  period: function (val) {
   this.getCallerNumbers();
   // this.separateQueueExtensionData();
  },
  groupData: function (val) {
   if (this.selectedElement) {
    let grouped = "grouped";
    this.separateQueueExtensionData(grouped);
   }
  },
  searchQueue: function (val) {
   if (val && val.length <= 0) {
    this.searchQueue = null;
   }
  },
 },
 mounted() {
  this.getPbxMap();
  // this.$store.dispatch("pbxElements", null);
  if (!this.period && !this.pbxPeriodSelected) {
   this.period = this.currentMonthAndYearForPbx();
  }
  if (this.pbxPeriodSelected) this.period = this.pbxPeriodSelected;
  this.currentMonthAndYearForPbx();
  // this.separateQueueExtensionData();
  // if (this.pbxElements) {
  //   this.selectedElement = this.pbxElements;
  // }
 },
 computed: {
  filteredQueueData() {
   return this.queueData.filter((data) => {
    if (this.searchQueue) {
     let flag = false;
     this.searchQueue.forEach((queue) => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else {
     return data;
    }
   });
  },
  // filteredExtensionData() {
  //  return this.extensionData.filter((data) => {
  //   if (
  //    this.searchQueue &&
  //    !this.searchQueueName &&
  //    !this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return this.searchQueue.indexOf(data.queue) >= 0;
  //   } else if (
  //    this.searchQueue &&
  //    this.searchQueueName &&
  //    !this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueue.indexOf(data.queue) >= 0 && this.searchQueueName.indexOf(data.queueName) >= 0
  //    );
  //   } else if (
  //    this.searchQueue &&
  //    !this.searchQueueName &&
  //    this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueue.indexOf(data.queue) >= 0 && this.searchExtension.indexOf(data.element) >= 0
  //    );
  //   } else if (
  //    this.searchQueue &&
  //    !this.searchQueueName &&
  //    !this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueue.indexOf(data.queue) >= 0 &&
  //     this.searchExtensionName.indexOf(data.elementName) >= 0
  //    );
  //   } else if (
  //    this.searchQueue &&
  //    this.searchQueueName &&
  //    this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueue.indexOf(data.queue) >= 0 &&
  //     this.searchQueueName.indexOf(data.queueName) >= 0 &&
  //     this.searchExtension.indexOf(data.element) >= 0
  //    );
  //   } else if (
  //    this.searchQueue &&
  //    this.searchQueueName &&
  //    !this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueue.indexOf(data.queue) >= 0 &&
  //     this.searchQueueName.indexOf(data.queueName) >= 0 &&
  //     this.searchExtensionName.indexOf(data.elementName) >= 0
  //    );
  //   } else if (
  //    !this.searchQueue &&
  //    this.searchQueueName &&
  //    !this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return this.searchQueueName.indexOf(data.queueName) >= 0;
  //   } else if (
  //    !this.searchQueue &&
  //    this.searchQueueName &&
  //    this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueueName.indexOf(data.queueName) >= 0 &&
  //     this.searchExtension.indexOf(data.element) >= 0
  //    );
  //   } else if (
  //    !this.searchQueue &&
  //    this.searchQueueName &&
  //    !this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueueName.indexOf(data.queueName) >= 0 &&
  //     this.searchExtensionName.indexOf(data.elementName) >= 0
  //    );
  //   } else if (
  //    !this.searchQueue &&
  //    this.searchQueueName &&
  //    this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueueName.indexOf(data.queueName) >= 0 &&
  //     this.searchExtension.indexOf(data.element) >= 0 &&
  //     this.searchExtensionName.indexOf(data.elementName) >= 0
  //    );
  //   } else if (
  //    !this.searchQueue &&
  //    !this.searchQueueName &&
  //    this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchExtension.indexOf(data.element) >= 0 &&
  //     this.searchExtensionName.indexOf(data.elementName) >= 0
  //    );
  //   } else if (
  //    !this.searchQueue &&
  //    !this.searchQueueName &&
  //    this.searchExtension &&
  //    !this.searchExtensionName
  //   ) {
  //    return this.searchExtension.indexOf(data.element) >= 0;
  //   } else if (
  //    !this.searchQueue &&
  //    !this.searchQueueName &&
  //    !this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return this.searchExtensionName.indexOf(data.elementName) >= 0;
  //   } else if (
  //    this.searchQueue &&
  //    this.searchQueueName &&
  //    this.searchExtension &&
  //    this.searchExtensionName
  //   ) {
  //    return (
  //     this.searchQueue.indexOf(data.queue) >= 0 &&
  //     this.searchQueueName.indexOf(data.queueName) >= 0 &&
  //     this.searchExtension.indexOf(data.element) >= 0 &&
  //     this.searchExtensionName.indexOf(data.elementName) >= 0
  //    );
  //   } else {
  //    return data;
  //   }
  //  });
  // },
  filteredExtensionData() {
   return this.extensionData.filter((data) => {
    if (this.searchQueue && !this.searchExtension) {
     let flag = false;
     this.searchQueue.forEach((queue) => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else if (!this.searchQueue && this.searchExtension) {
     let flag = false;
     this.searchExtension.forEach((element) => {
      if (element.includes(data.element) && element.includes(data.elementName)) {
       flag = true;
      }
     });
     return flag;
    } else if (this.searchQueue && this.searchExtension) {
     let flag = false;
     let flagQueue = false;
     let flagExtension = false;
     this.searchExtension.forEach((element) => {
      if (element.includes(data.element) && element.includes(data.elementName)) {
       flagExtension = true;
      }
     });
     this.searchQueue.forEach((queue) => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flagQueue = true;
      }
     });
     if (flagExtension && flagQueue) {
      flag = true;
     }
     return flag;
    } else {
     return data;
    }
   });
  },
  ...mapGetters(["hostName", "hostTimeZone", "pbxPeriodSelected", "pbxElements", "pbxMap"]),
 },
};
</script>

<style></style>
